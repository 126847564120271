// material-ui
import { styled, Theme } from '@mui/material/styles'
import { Box } from '@mui/material'

// ==============================|| DRAWER HEADER - STYLED ||============================== //

interface Props {
  theme: Theme,
  open: boolean
}

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }: Props) => ({
  ...theme.mixins.toolbar,
  minHeight: 61,
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'flex-start' : 'center',
  paddingLeft: theme.spacing(open ? 3 : 0),
  borderWidth: 0,
  borderStyle: 'solid',
  borderBottomWidth: 'thin',
  borderColor: '#2D3748',
  '& h5': {
    color: '#fff',
  },
}))

export default DrawerHeaderStyled
