import { styled as MUIStyled } from '@mui/material'

type Props = {
  className?: string;
}

const PageNotFound = MUIStyled(({ className }: Props): JSX.Element => {
  return (
    <div className={['404-page', className].join(' ')}>
      <div className="page-content">
        <div className="page-title">404 - Page Not Found</div>
        <div className="message">We're sorry, the page you requested could not be found</div>
      </div>
    </div>
  )
})({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',

  '& .page-content': {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    rowGap: 16,
  },

  '& .page-title': {
    fontSize: 32,
    lineHeight: 1.2,
    fontWeight: 600,
  },
})

export default PageNotFound
