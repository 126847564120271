// material-ui
import { styled, Theme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'

// project import
import { drawerWidth, drawerBgColor } from 'config'

const openedMixin = (theme: Theme) => ({
  backgroundColor: drawerBgColor,
  width: drawerWidth,
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxShadow: 'none',
})

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  borderRight: 'none',
})

// ==============================|| DRAWER - MINI STYLED ||============================== //
interface IProps {
  open: boolean,
  variant?: string
}

const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })<IProps>(({ theme, open }): any => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open ? {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  } : ''),
  ...(!open ? {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  } : ''),
}))

export default MiniDrawerStyled
