import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import moment from 'moment'
import 'moment/locale/vi'

import enTrans from './en.json'
import viTrans from './vi.json'

const languageResources = {
  en: {
    translation: enTrans,
  },
  vi: {
    translation: viTrans,
  },
}

export const initTranslations = () => {
  const defaultLocale = 'vi'

  moment.locale(defaultLocale)

  i18next.use(initReactI18next).init({
    resources: languageResources,
    interpolation: {
      escapeValue: false,
    },
    lng: defaultLocale,
  })
}
