import { delay, put, takeEvery } from 'redux-saga/effects'
import { layoutActions } from '../slices/layoutSlice'

function* showMessage({ payload }: { payload: { id: string } }) {
  try {
    yield delay(5000)
    yield put(layoutActions.hideMessage(payload.id))
  } catch { }
}

export default function* layoutSaga() {
  yield takeEvery(layoutActions.showMessage, showMessage)
}